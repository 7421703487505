@font-face {
  font-family: 'ITC Charter Com';
  src: url('../assets/fonts/ITCCharterCom-Regular.eot');
  src: url('../assets/fonts/ITCCharterCom-Regular.eot?#iefix') format('embedded-opentype'),
      url('../assets/fonts/ITCCharterCom-Regular.woff2') format('woff2'),
      url('../assets/fonts/ITCCharterCom-Regular.woff') format('woff'),
      url('../assets/fonts/ITCCharterCom-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'HelveticaNeueLT Pro 55 Roman';
  src: url('../assets/fonts/HelveticaNeueLTPro-Roman.eot');
  src: url('../assets/fonts/HelveticaNeueLTPro-Roman.eot?#iefix') format('embedded-opentype'),
      url('../assets/fonts/HelveticaNeueLTPro-Roman.woff2') format('woff2'),
      url('../assets/fonts/HelveticaNeueLTPro-Roman.woff') format('woff'),
      url('../assets/fonts/HelveticaNeueLTPro-Roman.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

ol, ul {
  padding-left: 30px;
}

.board-row {
  display:flex;
}

.status {
  margin-bottom: 10px;
}

/* .square {
  background: #fff;
  border: 1px solid #999;
  float: left;
  font-size: 24px;
  font-weight: bold;
  line-height: 34px;
  height: 34px;
  margin-right: -1px;
  margin-top: -1px;
  padding: 0;
  text-align: center;
  width: 34px;
} */

.square:focus {
  outline: none;
}

.kbd-navigation .square:focus {
  background: #ddd;
}

.game {
  display: flex;
  flex-direction: column;
  padding-bottom: 40px;
}
.game-info {
  min-height: 35px;
}
.game p {
  color: #727272;
  font-family: 'HelveticaNeueLT Pro 55 Roman';
  font-size: 21px;
  text-align: center;
  margin-bottom: 10px;
}

#root {
  background: #fff
}

.board-row-first div:first-child .square{
  background-color:#EB8C00
}

.board-row-first div:nth-child(2) .square{
  background-color:#FFB600
}

.board-row-first div:nth-child(3) .square{
  background-color:#EB8C00
}

.board-row-first div:nth-child(4) .square{
  background-color:#FFB600
}

.board-row-first div:nth-child(5) .square{
  background-color:#EB8C00
}

.board-row-second div:first-child .square{
  background-color:#FFB600
}

.board-row-second div:nth-child(2) .square{
  background-color:#EB8C00
}

.board-row-second div:nth-child(3) .square{
  background-color:#FFB600
}

.board-row-second div:nth-child(4) .square{
  background-color:#EB8C00
}

.board-row-second div:nth-child(5) .square{
  background-color:#FFB600
}

.board-row-third div:first-child .square{
  background-color:#EB8C00
}

.board-row-third div:nth-child(2) .square{
  background-color:#FFB600
}

.board-row-third div:nth-child(3) .square{
  background-color:#EB8C00
}

.board-row-third div:nth-child(4) .square{
  background-color:#FFB600
}

.board-row-third div:nth-child(5) .square{
  background-color:#EB8C00
}

.board-row-fourth div:first-child .square{
  background-color:#FFB600
}

.board-row-fourth div:nth-child(2) .square{
  background-color:#EB8C00
}

.board-row-fourth div:nth-child(3) .square{
  background-color:#FFB600
}

.board-row-fourth div:nth-child(4) .square{
  background-color:#EB8C00
}

.board-row-fourth div:nth-child(5) .square{
  background-color:#FFB600
}

.board-row-five div:first-child .square{
  background-color:#EB8C00
}

.board-row-five div:nth-child(2) .square{
  background-color:#FFB600
}

.board-row-five div:nth-child(3) .square{
  background-color:#EB8C00
}

.board-row-five div:nth-child(4) .square{
  background-color:#FFB600
}

.board-row-five div:nth-child(5) .square{
  background-color:#EB8C00
}